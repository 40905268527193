<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" content="详细信息" class="margin-bottom margin-top-xs"></el-page-header>
		<el-divider content-position="left">基本信息</el-divider>
		<div>
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="100px" class="flex flex-wrap">
					<el-form-item label="姓名" prop="name" :rules="rules.required">
						<div class="form-item-box">
							<el-input v-model="form.name" disabled="disabled" placeholder="请填写顾客姓名"></el-input>
						</div>
					</el-form-item>

					<!-- <el-form-item label="填写日期" prop="created_at">
						<div class="form-item-box">
							<el-date-picker v-model="form.created_at" type="date" placeholder="请填写日期"
								format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" style="width: 100%;">
							</el-date-picker>
						</div>
					</el-form-item> -->

					<el-form-item label="填写日期" prop="created_at">
						<el-date-picker style="width: 100%;" disabled="disabled" v-model="form.created_at" type="datetime"
							format="yyyy-MM-dd hh:mm:ss" value-format="yyyy-MM-dd hh:mm:ss" placeholder="填写日期">
						</el-date-picker>
					</el-form-item>


					<el-form-item label="联系电话" prop="phone" :rules="[rules.required,rules.phone]">
						<div class="form-item-box">
							<el-input disabled="disabled" v-model="form.phone" placeholder="请填写顾客联系电话"></el-input>
						</div>
					</el-form-item>
					<el-form-item label="记录人" prop="operater_name" :rules="rules.required">
						<div class="form-item-box">
							<el-input disabled="disabled" v-model="form.operater_name" placeholder="请填写记录人"></el-input>
						</div>
					</el-form-item>

				</el-form>
			</div>
			<el-divider content-position="left">详细资料</el-divider>

			<div class="form_box">
				<el-form ref="form" :model="form" label-width="300px" label-position="left" style="margin-left: 50px;">
				
					<el-form-item :label="item.id+'、'+item.title"  v-for="(item,index) in itemList" :key="index">
						<span v-if="item.score==1">很差</span>
						<span v-if="item.score==2">较差</span>
						<span v-if="item.score==3">一般</span>
						<span v-if="item.score==4">较好</span>
						<span v-if="item.score==5">很好</span>
					
						
					</el-form-item>
				
				<!-- 	<el-form-item label="2、内部之指示寻找标志足够吗？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="3、内部环境是否整洁适度？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="4、前台接待态度是否令阁下满意？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="5、环境是否干净？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="6、老师之仪容是否整洁？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="8、老师的技术是否令你满意？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="9、老师有否提供在家中如何进行家庭护理？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="10、在与老师交谈中有否令你感到不满意？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="11、老师助理的工作态度是否令你满意？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="12、收款时速度及态度是否令你满意？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="13、您在做服务过程中是否感觉服务舒适？">
						<span>很好</span>
					</el-form-item>
					<el-form-item label="14、你对改善本店工作的看法和意见？">
						<span>很好</span>
					</el-form-item> -->

				</el-form>
			</div>

		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	// 行政区划npm版本
	// import ChinaLocation from "china-location";
	// import list from "china-location/dist/location.json";
	export default {
		data() {
			return {
				rules,
				form: {
					id: this.$route.params.id,
					name: "",
					phone: '',
					operater_name: '',
					created_at: '',

				},
				itemList:[]
			};
		},
		computed: {

		},
		mounted() {
			this.getInfo()
		},
		methods: {
			//获取客户意详情
			getInfo() {
				this.$api.tablewx.getCustomerConsultingInfo({
					id: this.form.id
				}).then(res => {
					this.form = res.data
					this.itemList=res.data.item_list
					console.log(this.itemList)
					
				})
			},

		}
	};
</script>
<style scoped lang="scss">
	.form_box {
		max-width: 800px;
	}

	.form-item-box {
		width: 300px
	}
</style>
